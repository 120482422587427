<template>
    <Layout>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 variation-default height-500 bg_image"
             :style="{'background-image': `url(${require(`@/assets/images/_new/cherdrop-slider.jpg`)})`}">
        </div>
        <!-- End Slider Area -->
			<div class="about-area about-style-4 rn-section-gap">
				<div class="container">
					<div class="row row--40 align-items-center" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-6 mt_md--40 mt_sm--40">
							<div class="content">
								<div class="inner">
									<h3 class="title"><strong>无线投屏</strong></h3>
									<div class="description">
										无线投屏是中炘云网自研系统，历经多版本的内部场景磨练，为会议室投屏提供卓越体验的企业级产品。
									</div>
									<div class="mt-lg-5">
										<ul class="feature-list">
											<li class="adv">
												<div class="icon">
													<Icon name="check" size="16" icon-class="icon"/>
												</div>
												<div class="title-wrapper">
													<h4 class="title">兼容企业级网络架构</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon">
													<Icon name="check" size="16" icon-class="icon"/>
												</div>
												<div class="title-wrapper">
													<h4 class="title">功能强大的企业级管理后台</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon">
													<Icon name="check" size="16" icon-class="icon"/>
												</div>
												<div class="title-wrapper">
													<h4 class="title">企业办公软件集成</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon">
													<Icon name="check" size="16" icon-class="icon"/>
												</div>
												<div class="title-wrapper">
													<h4 class="title">信息发布，会议室信息展示</h4>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<img class="product-img" src="../assets/images/cherdrop/1.png" />
						</div>
					</div>
				</div>
			</div>

			<div class="about-area about-style-4 rn-section-gap bg-light">
				<div class="container">
					<div class="row row--40 align-items-center" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-6">
							<img class="product-img" src="../assets/images/_new/data-center.png" />
						</div>
						<div class="col-lg-6 mt_md--40 mt_sm--40">
							<div class="content">
								<div class="inner">
									<h3 class="title"><strong>数据中心业务</strong></h3>
									<div class="description">
										运营全国范围内多个数据中心，拥有BGP、单线、国际资源等，公司提供数据中心租赁、服务器托管及租用、ISP等业务，专业售后团队运维经验15年+，为用户提供7×24小时保障服务。
									</div>
									<div class="mt-lg-5">
										<Counter
											:counter-style="5"
											text-align="center"
											:counter-data="counterData"
											column="col-lg-4 col-md-6 col-sm-6"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="about-area about-style-4 rn-section-gap">
				<div class="container">
					<div class="row row--40 align-items-center" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-6 mt_md--40 mt_sm--40">
							<div class="content">
								<div class="inner">
									<h3 class="title"><strong>云计算业务</strong></h3>
									<div class="description">
										具有丰富的公有云资源，为上云用户提供全系列产品以及售后服务，为各行业提供上云方案，并为用户提供上云指导和迁移服务。在专业领域，针对GPU需求用户提供专业的GPU解决方案和多种产品，满足不同层次用户的多种需求。
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6" style="text-align: right">
							<img class="product-img" src="../assets/images/_new/cloud.png" />
						</div>
					</div>
				</div>
			</div>

			<div class="about-area about-style-4 rn-section-gap bg-light">
				<div class="container">
					<div class="row row--40 align-items-center" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-6">
							<img class="product-img" src="../assets/images/_new/net-safe.png" />
						</div>
						<div class="col-lg-6 mt_md--40 mt_sm--40">
							<div class="content">
								<div class="inner">
									<h3 class="title"><strong>网络安全</strong></h3>
									<div class="description">
										中炘云网自主研发，进行分布式部署，拥有超高防护能力，可以各行业多用户提供更有效的高防解决方案，无需调整网络拓扑，接入更方便，及时数据展示，让用户实时掌握网络动态。
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

        <!-- Start Elements Area -->
        <div class="rwt-tab-area rn-section-gap">
            <div class="container">
                <div class="row mb--40" data-aos="fade-up" data-aos-delay="100">
                    <div class="col-lg-12 text-center">
                        <h3 class="color-blue">四大数字化转型核心能力，助力企业畅行云途</h3>
                    </div>
                </div>
                <div class="row">
									<div v-for="(item, idx) in homeData" class="col-lg-3 col-md-6 col-sm-12 col-12 plr--10 ptb--10" data-aos="fade-up" :data-aos-delay="100 + 100 * idx">
										<div class="card-box card-style-1 text-left">
											<div class="inner">
												<div class="full-image">
													<img :src="item.image" :alt="item.title">
												</div>
												<div class="content">
													<h4 class="title mb--20">
														{{ item.title }}
													</h4>
													<p class="description b1 color-gray mb--0">
														{{ item.description }}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
            </div>
        </div>
        <!-- End Elements Area -->
			<div class="rwt-tab-area rn-section-gap bg-light">
				<div class="container">
					<div class="row mb--50" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-12 text-center">
							<h3>增值服务</h3>
						</div>
					</div>
					<div class="row" data-aos="fade-up" data-aos-delay="200">
						<div class="col-lg-4 col-md-12 service">
							<div class="container" :style="{backgroundImage: 'url('+ require(`@/assets/images/_new/s0.jpg`) +')'}">
								<div class="title">服务保障</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-6 service">
							<div class="container no-bg">
								<img class="img" src="../assets/images/_new/s11.png">
								<div class="content">致力于帮客户解决问题</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-6 service">
							<div class="container no-bg">
								<img class="img" src="../assets/images/_new/s12.png">
								<div class="content">快速响应用户需求</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-6 service">
							<div class="container no-bg">
								<img class="img" src="../assets/images/_new/s13.png">
								<div class="content">始终以服务用户为宗旨</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-6 service">
							<div class="container no-bg">
								<img class="img" src="../assets/images/_new/s14.png">
								<div class="content">不断升级不断提高服务水平</div>
							</div>
						</div>
					</div>
					<div class="row" data-aos="fade-up" data-aos-delay="200">
						<div class="col-lg-2 col-md-6 service">
							<div class="container no-bg">
								<img class="img" src="../assets/images/_new/s15.png">
								<div class="content">专业技术团队</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-6 service">
							<div class="container no-bg">
								<img class="img" src="../assets/images/_new/s16.png">
								<div class="content">7×24小时售后服务</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-6 service">
							<div class="container no-bg">
								<img class="img" src="../assets/images/_new/s17.png">
								<div class="content">具备专业操作资质认证</div>
							</div>
						</div>
						<div class="col-lg-2 col-md-6 service">
							<div class="container no-bg">
								<img class="img" src="../assets/images/_new/s18.png">
								<div class="content">定期培训考核</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-12 order-lg-last order-md-first order-sm-first service">
							<div class="container" :style="{backgroundImage: 'url('+ require(`@/assets/images/_new/s1.jpg`) +')'}">
								<div class="title">技术优势</div>
							</div>
						</div>
					</div>
					<div>
					</div>
				</div>
			</div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
		import Counter from "@/components/elements/counterUp/Counter.vue";
		import Icon from "@/components/icon/Icon.vue";

    export default {
        name: 'Home',
        components: {Icon, Counter, Layout},
        data() {
            return {
							counterData: [
								{
									number: '15年+',
									title: '运维经验',
								},
								{
									number: '7×24',
									title: '保障服务',
								}
							],
							homeData: [
								{
									title: '强大的多云管理平台',
									description: '面向多云/混合IT环境的SmartOps下一代云管理平台，包含CMDB、智能监控、费用分析、堡垒机、作业编排、流程审批、服务目录、组织权限。',
									image: require(`@/assets/images/_new/home1.png`)
								},
								{
									title: '广泛的云连接能力',
									description: '拥有一张横跨多个超大规模公有云和几十个大规模IDC之间的云联网，为客户构建高速稳定、低成本、灵活弹性的云连接。',
									image: require(`@/assets/images/_new/home2.png`)
								},
								{
									title: '专业的云托管服务能力',
									description: '中国MSP领域规模较大的托管服务团队，全栈技术覆盖，累积多项认证。',
									image: require(`@/assets/images/_new/home3.png`)
								},
								{
									title: '成熟的云原生开发能力',
									description: '顶级的云原生开发工程师，基于云原生实践经验，集成DevOps能力，提供云上应用开发、测试、部署及运维自动化。',
									image: require(`@/assets/images/_new/home4.png`)
								}
							]
            }
        }
    }
</script>
<style lang="scss">
.service {
	padding: 12px !important;
	.container {
		height: 240px;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-top: 40px;
		&.no-bg {
			background-color: rgb(248, 249, 250);
		}
		.title {
			font-size: 36px;
			color: white;
			line-height: 160px;
		}
		.img {
			width: 60px;
			height: 60px;
		}
		.content {
			color: #0f0f11;
			font-size: 22px;
			text-align: center;
			margin: 16px 12px 0 12px;
		}
	}
}
.description,.content {
	text-align: justify;
}
.adv {
	 margin: 20px 0;
 }
</style>
