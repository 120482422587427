import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Cloud from '../views/Cloud.vue'
import Idc from '../views/Idc.vue'
import Gpu from '../views/Gpu.vue'
import CherDrop from '../views/Cherdrop.vue'
import Safe from '../views/Safe.vue'
import About from '../views/About.vue'
import ErrorPage from '../views/404'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '首页 - 中炘云网',
        },
    },
    {
        path: '/screen-cast',
        name: 'CherDrop',
        component: CherDrop,
        meta: {
            title: '无线投屏 - 中炘云网',
        },
    },
    {
        path: '/safe',
        name: 'Safe',
        component: Safe,
        meta: {
            title: '无线安防 - 中炘云网',
        },
    },
    {
        path: '/cloud',
        name: 'Cloud',
        component: Cloud,
        meta: {
            title: '云计算 - 中炘云网',
        },
    },
    {
        path: '/idc',
        name: 'Idc',
        component: Idc,
        meta: {
            title: 'IDC - 中炘云网',
        },
    },
    {
        path: '/gpu',
        name: 'GPU',
        component: Gpu,
        meta: {
            title: 'GPU云 - 中炘云网',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: '关于我们 - 中炘云网',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Error',
        component: ErrorPage,
        meta: {
            title: '404 - 中炘云网',
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
