<template>
    <ul class="mainmenu">
			<li><div class="menu-item"><router-link to="/">首页</router-link></div></li>
			<li><div class="menu-item"><router-link to="/screen-cast">无线投屏</router-link></div></li>
			<li><div class="menu-item"><router-link to="/safe">无线安防</router-link></div></li>
			<li class="has-droupdown has-menu-child-item">
				<div class="menu-item">
					<a href="#">云计算</a>
				</div>
				<ul class="submenu">
					<li><router-link to="/cloud">云计算</router-link></li>
					<li><router-link to="/idc">IDC</router-link></li>
					<li><router-link to="/gpu">GPU云</router-link></li>
				</ul>
			</li>
			<li><div class="menu-item"><router-link to="/about">关于我们</router-link></div></li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>
