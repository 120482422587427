<template>
	<Layout>
		<div class="slider-area slider-style-1 variation-default height-500 bg_image"
				 :style="{'background-image': `url(${require(`@/assets/images/_new/3.png`)})`}">
		</div>
		<div class="about-area about-style-4 rn-section-gap cloud-desc bg-light">
			<div class="container">
				<div class="row align-items-center mb--30" data-aos="fade-up" data-aos-delay="100">
					<div class="col-lg-12">
						<div class="content">
							<div class="inner text-center">
								<h3 class="title"><strong>无线安防</strong></h3>
							</div>
						</div>
					</div>
				</div>
				<div class="row align-items-center" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-12">
						<div class="content">
							“极炘”—全球远程音视频多终端调度指挥平台，作为新一代AR可视化智慧安防系统，可为安保人员开启火眼金睛，慧眼如炬精准识别研判，配合远端云平台实时指挥调度，将安防系统从“被动查询、孤立响应’到“主动防护、全面掌控”的根本性改变，从而提高技防水平。
						</div>
					</div>
				</div>
				<div class="row mt--30">
					<div v-for="(item, idx) in productData" class="col-lg-3 col-md-6 col-sm-12 col-12 padding--10" data-aos="fade-up" :data-aos-delay="100 + 100 * idx">
						<div class="card-box card-style-1 text-left" style="background: linear-gradient(180deg, rgba(106,218,241,0.2) 0%, rgba(142,242,249,0.01) 100%, rgba(2,0,36,1) 100%);
">
							<div class="inner">
								<div class="full-image">
									<img :src="item.image" :alt="item.title">
								</div>
								<div class="content mt--20 mb--20">
									<h4 class="title">
										{{ item.title }}
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="about-area about-style-4 rn-section-gap">
			<div class="container">
				<div class="row row--20" data-aos="fade-up" data-aos-delay="100">
					<div class="col-lg-5">
						<img class="product-img" src="../assets/images/safe/d1.jpg" style="width: 100%" />
					</div>
					<div class="col-lg-7 mt--20 mt_md--40 mt_sm--40">
						<div class="content">
							<div class="inner">
								<h4 class="title"><strong>AR+AI助力安防</strong></h4>
								<div class="description" style="font-size: 18px">
									<div>
										“极炘”系统为智慧安防市场提供一体化综合解决方案，可在日常执勤巡逻应急处突、重大活动安保、身份核验等多场景应用，紧贴智慧安防发展需求在突发事件紧急救援、防暴反恐、配合缉私缉毒、追捕逃犯、火险扑救、车站巡查、港口稽查、园区巡逻、远程指挥、应急管理等各项社会治安执法活动以及社会救援中将发挥更大作用。
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<separator />

		<div class="about-area about-style-4 rn-section-gap">
			<div class="container">
				<div class="row row--20" data-aos="fade-up" data-aos-delay="100">
					<div class="col-lg-7 mt--20 mt_md--40 mt_sm--40">
						<div class="content">
							<div class="inner">
								<h4 class="title"><strong>智慧安防—让安防布控无死角</strong></h4>
								<div class="description" style="font-size: 18px">
									<div>
										基于AR智能眼镜的可视化应急指挥安防调度系统，为现场安保人员解放双手，实现第一人称视角的音视频传输、动态多目标人脸识别、虚实结合的信息显示等功能。通过远程指挥调度功能，后台指挥中心可通过现场安防人员佩戴的AR眼镜回传的实时画面，及时了解现场态势，便于精准决策，即时指挥，协同作战。
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-5">
						<img class="product-img" src="../assets/images/safe/d2.png" style="width: 100%" />
					</div>
				</div>
			</div>
		</div>

		<separator />

		<div class="about-area about-style-4 rn-section-gap">
			<div class="container">
				<div class="row row--20" data-aos="fade-up" data-aos-delay="100">
					<div class="col-lg-5">
						<img class="product-img" src="../assets/images/safe/d3.jpg" style="width: 100%" />
					</div>
					<div class="col-lg-7 mt--20 mt_md--40 mt_sm--40">
						<div class="content">
							<div class="inner">
								<h4 class="title"><strong>双端联动，一线工作人员与管理人员高效协作</strong></h4>
								<div class="description" style="font-size: 18px">
									一线使用AR设备的工作人员端可以与后台管理系统使用人员之间实现无缝协作和同步，管理人员通过系统能够实时响应并处理一线事项，提供协同工作的环境。通过智能规划、一键报警、智能调度、数据监测、智能巡检、数据回溯、远程监视等功能，构建系统高效协作、快速响应、全面监测、决策支持的核心优势，为客户提供了高效、安全、可靠的安防解决方案。
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '../components/common/Layout.vue'
import Separator from "@/components/elements/separator/Separator.vue";
import Icon from "@/components/icon/Icon.vue";

export default {
	name: 'CheerDrop',
	components: {Icon, Separator, Layout},
	data() {
		return {
			productData: [
				{
					title: '人工智能技术',
					image: require(`@/assets/images/safe/a1.png`)
				},
				{
					title: '开放多终端接入',
					image: require(`@/assets/images/safe/a2.png`)
				},
				{
					title: '多级联动策略',
					image: require(`@/assets/images/safe/a3.png`)
				},
				{
					title: '智能化管理',
					image: require(`@/assets/images/safe/a4.png`)
				}
			],
		}
	}
}
</script>
<style lang="scss">
.service {
	padding: 12px !important;
	.container {
		height: 240px;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-top: 40px;
		&.no-bg {
			background-color: rgb(248, 249, 250);
		}
		.title {
			font-size: 36px;
			color: white;
			line-height: 160px;
		}
		.img {
			width: 60px;
			height: 60px;
		}
		.content {
			color: #0f0f11;
			font-size: 22px;
			text-align: center;
			margin: 16px 12px 0 12px;
		}
	}
}
.items {
	div {
		margin-top: 10px;
	}
}
table {
	border-radius: 4px;
	thead {
		th {
			text-align: center;
			padding: 12px !important;
			font-size: 20px;
			vertical-align: middle;
		}
	}
	tbody {
		td {
			border-color: rgb(222, 226, 230) !important;
			height: 100%;
			vertical-align: middle;
		}
		.description {
			padding: 8px 12px;
			.title {
				font-weight: bold;
			}
			li {
				font-size: 16px;
			}
		}
		.func {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
	}
}
.content-drop {
	color: #0f0f11;
	font-size: 22px;
	text-align: center;
	margin: 16px 12px 0 12px;
}
</style>
